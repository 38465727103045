import React from 'react'
import videoBg from '../assets/videoBg.mp4'
import { SocialIcon } from 'react-social-icons';

const Main = () => {
  return (
    <div className='main'>
        <div className="overlay"></div>
        <video src={videoBg} autoPlay loop muted />
        <div className="content">
            <h1>Welcome</h1>
            <p>You can contact me via:</p>
            <br/>
            <div class="social-links">
              <SocialIcon 
                url="https://www.facebook.com/lilynovaart/"
                fgColor="#ffffff"
                target="_blank" />
              <SocialIcon
                url="https://www.instagram.com/lilynovaart/"
                fgColor="#ffffff"
                target="_blank" />
              <SocialIcon
                url="https://bluethumb.com.au/lily-nova"
                fgColor="#ffffff"
                target="_blank" />
            </div>
        </div>
    </div>
  )
}

export default Main